import LayoutPage from './LayoutPage';

function Page404() {
  return (
    <LayoutPage>
      <h2>404 Not found</h2>
      <p>หาไม่เจอหรือเธอไม่มี</p>
    </LayoutPage>
  );
}

export default Page404;
